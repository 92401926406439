import React from 'react';
import './App.css';
import Canvas from "./p5/usecase/Canvas";
import Main from './p5/usecase/Main';

function App() {
  return (
    <div className="App">
      <Canvas sketch={Main} />
    </div>
  );
}

export default App;
