import p5 from "p5";

const Main = (p: p5) => {
  const videos: Array<p5.MediaElement | undefined> = [undefined, undefined, undefined];
  const cutSpan = 134;
  const unknownNum = 10;
  let isPlaying = false;


  let far = 0;
  let angleX = 0;
  let angleY = 0;

  p.preload = () => {};

  p.setup = () => {
    const vInput1 = p.createFileInput((x) => loadFile(x, 0));
    const vInput2 = p.createFileInput((x) => loadFile(x, 1));
    const vInput3 = p.createFileInput((x) => loadFile(x, 2));
    vInput1.style("margin: 2rem");
    vInput2.style("margin: 2rem");
    vInput3.style("margin: 2rem");
    p.createCanvas(p.displayWidth, p.displayHeight, "webgl");
    p.background(0);
    p.colorMode(p.HSB, 255, 255, 255, 100);
    p.blendMode(p.NORMAL);
    p.noCursor();
  };

  p.draw = () => {
    p.background(0);
    p.stroke(255);

    p.translate(0, 0, far);
    p.rotateX(p.radians(angleX));
    p.rotateY(p.radians(angleY));
    if (videos[1] !== undefined) {
      p.noStroke();
      p.texture(videos[1]);
    } else {
      p.stroke(255);
      p.fill(255);
    }

    const centerRect = rect(0, 0);
    const centerCut = (cutSpan / 1080) * centerRect.height;
    const widthZoom = centerRect.width * (cutSpan / 1080) * 2;
    const heightZoom = centerRect.height * (cutSpan / 1080) * 2;

    p.rect(
      centerRect.xPos - widthZoom / 2,
      centerRect.yPos - heightZoom / 2,
      centerRect.width + widthZoom,
      centerRect.height + heightZoom,
    );
    p.fill(0);

    // 画面カット用
    p.push();
    p.translate(0, 0, 1);
    p.noStroke();
    p.rect(
      centerRect.xPos - widthZoom / 2 - unknownNum,
      centerRect.yPos - heightZoom / 2 - unknownNum,
      centerRect.width + widthZoom + (unknownNum *  2),
      centerCut + unknownNum,
    );
    p.rect(
      centerRect.xPos - widthZoom / 2 - unknownNum,
      centerRect.yPos + centerRect.height + heightZoom / 2 - centerCut,
      centerRect.width + widthZoom + (unknownNum * 2),
      centerCut + unknownNum,
    );

    p.pop();
    // 画面カット用ここまで

    const leftRect = rect(-(p.width / 3) - widthZoom / 2, 0);
    p.push();
    p.translate(leftRect.xPos + leftRect.width, 0);
    p.rotateY(p.radians(90));
    p.translate(-leftRect.width, 0);
    if (videos[0] !== undefined) {
      p.noStroke();
      p.texture(videos[0]);
    } else {
      p.fill(255);
      p.stroke(255);
    }
    p.rect(0, -leftRect.height / 2, leftRect.width, leftRect.height);
    // 画面カット用
    p.noStroke();
    p.translate(0, 0, 1);
    const cutLeft = (192 / 1920) * leftRect.width;
    p.fill(0);
    p.rect(0 - 10, -leftRect.height / 2 - 5, cutLeft + 10, leftRect.height + 10);
    p.pop();
    // 画面カット用ここまで

    const rightRect = rect(p.width / 3 + widthZoom / 2, 0);
    p.push();
    p.translate(rightRect.xPos, 0);
    p.rotateY(p.radians(-90));
    if (videos[2] !== undefined) {
      p.noStroke();
      p.texture(videos[2]);
    } else {
      p.stroke(255);
      p.fill(255);
    }
    p.rect(0, -rightRect.height / 2, rightRect.width, rightRect.height);
    const cutRight = (192 / 1920) * rightRect.width;
    // 画面カット用
    p.noStroke();
    p.translate(0, 0, 1);
    p.fill(0);
    p.rect(
      rightRect.width - cutRight,
      -rightRect.height / 2 - 5,
      cutRight + 10,
      rightRect.height + 10,
    );
    p.pop();
    // 画面カット用ここまで

    // p.rect(-p.width / 5 + p.width / 100, 0, (16 * p.width) / 50, (9 * p.width) / 50);
    // p.rect(p.width / 5 + p.width / 100, 0, (16 * p.width) / 50, (9 * p.width) / 50);
  };

  p.mousePressed = () => {
    if (p.mouseX > 0 && p.mouseX < p.width && p.mouseY > 0 && p.mouseY < p.height) {
      try {
        p.fullscreen(true);
      } catch (e) {}
    }
  };

  p.mouseWheel = (event) => {
    if (isPlaying) {
      far += (event as any).delta;
    }
  };

  p.touchStarted = () => {};

  p.keyPressed = () => {
    if (p.key === " ") {
      playOrStop();
    }

    if (p.key === "w") {
      angleX += 1;
    }

    if (p.key === "s") {
      angleX -= 1;
    }

    if (p.key === "a") {
      angleY += 1;
    }

    if (p.key === "d") {
      angleY -= 1;
    }
  };

  const playOrStop = () => {
    if (isPlaying) {
      isPlaying = false;
      videos.forEach((x) => {
        if (x !== undefined) x.stop();
      });
      Array.from(document.getElementsByTagName("input")).forEach((x) => (x.hidden = false));
    } else {
      isPlaying = true;
      videos.forEach((x) => {
        if (x !== undefined) x.play();
      });
      Array.from(document.getElementsByTagName("input")).forEach((x) => (x.hidden = true));
    }
  };

  const rect = (x: number, y: number) => {
    const width = p.width / 3;
    const height = (width / 16) * 9;
    const xPos = x - width / 2;
    const yPos = y - height / 2;
    return { xPos, yPos, width, height };
  };

  const loadFile = (file: p5.File, index: number) => {
    const video = p.createVideo(file.data);
    video.volume(0.2);
    ((video as unknown) as p5.Element).hide();
    videos[index] = video;
  };
};

export default Main;
